.header {
    &__container {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        background-color: #006DAA;
        border-bottom: 2px solid #FFFFFF;
    }

    &__title {
        margin: 0 0 0 10px;
        font-size: 1.325rem;
        font-weight: normal;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-left: 10px;
    }
}