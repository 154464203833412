.drop-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 50px;
    background-color: rgb(186, 177, 177);;
    outline: 1px solid #000000;

    &__is-over {
        outline-color: #0000FF;
    }

    &__is-dropped {
        outline: none;
    }
}