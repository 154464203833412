.session {
    &__container {
        margin: 0;
        padding: 0;
    }

    &__content {
        position: absolute;
        top: 79px;
        bottom: 33px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        margin: 0 0 2px 0;
        color: #000000;
        background-color: #FFFFFF;
        overflow: auto;
    }
}
