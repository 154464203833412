.package-instruction {
    &__container {
        padding: 1rem 2rem 1rem 1rem;
        font-family: arial;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        
        > h5 {
            font-family: arial;
            font-size: 1rem;
        }
    }

    &__content {
        > * {
            font-size: 0.9125rem;
        }

        > h6 {
            margin-bottom: 1rem;
        }

        > p {
            padding-bottom: 1rem;
        }

        > ul > li {
            margin-bottom: 0.5rem;
        }

        > table {
            margin: 2rem 0;
            border-collapse: 'collapse';

            td {
                max-width: 15rem;
                text-align: center;

                &:first-child {
                    text-align: left;
                    font-weight: bold;
                }

                p {
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}