.drag_and_drop_options {
    &__container {
        display: flex;
        align-items: center;
        gap: 3rem;
        width: max-content;
        margin: 1.5rem 0.5rem;

        &-is--long {
            flex-direction: column;
            align-items: flex-start;

            .answer, .drop-area {
                width: 600px;
            }

            .drag_and_drop_options__answers {
                margin: auto;
            }

            .drag_and_drop_options__questions-item--label {
                width: 180px;
            }
        }
    }

    &__questions {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            &--label {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 420px;
                padding: 1rem 0.5rem;
                border: 1px solid #000000;
            }

            &--feedback {
                display: inline-block;
                padding: 3px 5px;
                min-width: 85px;
                text-align: center;
                color: #FFFFFF;
                font-size: 0.75rem;
                background-color: #FF5252;
                border-radius: 4px;

                &-correct {
                    background-color: #4CAF50;
                }
            }
        }
    }

    &__answers {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        background-color: rgb(223, 223, 223);
        border: 1px solid #000000;
    }
}