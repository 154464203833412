/* Calculator */
.calculator {
    &__container {
        width: 230px;
        height: 380px;  
        padding: 1rem 0.5rem;
        font-family: 'JetBrains Mono';  
        background-color: #09508f;
        border-radius: 10px 10px 20px 20px;
        pointer-events: visible;
    }

    &__display-box{
        display: flex;
        height: 36px;
        width: 190px;
        margin: auto;
        text-align: right;
        background-color: #acb59f;      
        border-radius: 3px;
        border: 4px solid #bdbeb1;
        overflow: hidden;
      }
      
      &__display-text {
        margin-top: 0;
        margin-left: auto;
        margin-right: 6px;
        color: #020202;
        font-family: 'Orbitron', sans-serif;
        font-size: 1.4rem;
      
        &--mrc {
          color: #020202;
          font-family: 'Orbitron', sans-serif;
          text-align: left;
          margin-left: 2px;
        }
      }

      &__solar-label {
        height: 52px;
        margin-top: 22px;
        color: #dee0e0;
      }
      
      &__solar-name {
        margin-left: 6px;
      }
      
      &__solar-text {
        display: flex;
        width: 125%;
        font-size: 0.8rem;
      }

      &__buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        width: 216px;
        padding: 3px;
        border: 2px solid #062156;
        border-radius: 6px;

        &-grid--left {
            display: grid;
            grid-template-columns: repeat(3, 0fr);
            grid-template-rows: repeat(6, 0fr);
            grid-gap: 0px;
        }

        &-grid--right {
            display: grid;
            grid-template-columns: repeat(1, 0fr);
            grid-template-rows: repeat(5, 0fr);
            grid-gap: 0px;          
            margin-left: 10px;
          }
      }

      &__button {
        width: 40px;
        height: 24px;
        margin: 5px;
        padding: 0;
        font-size: 0.85rem;
        font-family: 'JetBrains Mono';  
        color: #062156;       
        background-color: #dee0e0;   
        border-radius: 3px;

        &-is--active, &:active {
          background-color: orange !important;
          border-color: orange !important;
          border: none;
        }

        &-number { 
            color: #062156;
            background-color: #dee0e0;  
          }

        &-modifier {
            color: #fffbf8;
            background-color: #bf2733; 
          }

          &-submit {
            height: 60px;
            color: #fffbf8;
            background-color: #bf2733;
          }
          
      }
  }
  
  