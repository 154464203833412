.section-instruction {
    &__container {
        padding: 1rem;
        font-family: arial;
        font-size: 1rem;
    }

    &__content {
        > * {
            font-size: 0.9125rem;
        }

        h6 {
            margin: 1rem 0;
            font-size: 0.9125rem;
        }

        table {
            margin: 2rem 0;
            border-collapse: 'collapse';

            td {
                width: 8rem;
                text-align: center;

                p {
                    padding: 0.25rem;
                }
            }
        }

        p {
            padding-bottom: 1.25rem;
        }
        
        ul > li {
            margin-bottom: 0.5rem;
        }
    }
}